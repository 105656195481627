
html,
body,
body > div { /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
.fc-daygrid-body-balanced, .fc-daygrid-body-balanced > table {
  /* height: 100% !important; */
}
.fc-header-toolbar {
  flex-wrap: wrap-reverse;
  gap: 8px;
}
@media (max-width: 768px) {
  .fc .fc-toolbar-button {
    font-size: 14px;
    padding: 8px;
  }

  .fc .fc-button-primary {
    font-size: 14px;
  }

  .fc .fc-event-title {
    font-size: 14px;
  }
  .fc-direction-ltr .fc-daygrid-more-link {
    padding: 0;
    margin: 0;
  }
  .fc-dayGridMonth-view .fc-scroller.fc-scroller-liquid-absolute {
    overflow: hidden !important;
  }
}

@media (max-width: 425px) {
  .fc-toolbar-title {
    font-size: 22px;
  }
}
@media (min-width: 450px) and (max-width: 550px) {
  .fc-direction-ltr .fc-daygrid-more-link {
    bottom: 5px;
  }
}
@media (min-width: 394px) and (max-width: 450px) {
  .fc-direction-ltr .fc-daygrid-more-link {
    font-size: 12px;
    bottom: 9px;
  }
  .fc-timeGridWeek-view .fc-col-header-cell-cushion {
    font-size: 15px;
  }
}
@media (min-width: 360px) and (max-width: 394px) {
  .fc-direction-ltr .fc-daygrid-more-link {
    font-size: 9px;
    bottom: 9px;
  }
  .fc-timeGridWeek-view .fc-col-header-cell-cushion {
    font-size: 12px;
  }
}
@media (max-width: 359px) {
  .fc-timeGridWeek-view .fc-col-header-cell-cushion {
    font-size: 10px;
  }
  .fc-timeGridWeek-view .fc-timegrid-event-short p, b {
    font-size: 10px;
  }
  .fc-dayGridMonth-view .fc-col-header-cell-cushion {
    font-size: 12px;
  }
  .fc-daygrid-day-number {
    font-size: 14px;
  }
  .fc-direction-ltr .fc-daygrid-more-link {
    font-size: 7px;
    bottom: 9px;
  }
}