@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white !important;
  /* background-color: #f4f5f7; */
  height: 100vh;
  /* overflow: hidden; */
}
