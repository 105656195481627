@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
 @apply
 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800

}
.btn-secondary {
 @apply 
 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700

}
.btn-update {
 @apply 
 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-cyan-300 rounded-lg border border-gray-200 hover:bg-cyan-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-700 dark:bg-cyan-500 opacity-50 dark:text-white dark:border-cyan-600 dark:hover:text-white dark:hover:bg-cyan-700

}
.btn-delete {
 @apply 
 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-red-600 rounded-lg border border-gray-200 hover:bg-red-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-red-200 dark:focus:ring-red-700 dark:bg-red-700 opacity-50 dark:text-white dark:border-red-500 dark:hover:text-white dark:hover:bg-red-800;
}

.btn-call {
 @apply
focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900

}
.input-label {
 @apply 
 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
}
.input-label__secondary {
 @apply 
 block my-2 text-sm font-medium text-stone-800}

.input {
 @apply
 appearance-none text-sm block md:w-1/2  w-full min-w-full  bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500
}
.text-area {
 @apply
 block  p-3 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-100 focus:bg-white focus:border-gray-500
}

.upload-files{
 @apply
 mt-6 h-auto bg-gray-50 w-full cursor-pointer border border-dashed border-gray-300 hover:border-gray-200  rounded-lg text-center text-gray-700 text-sm leading-5 font-medium
}

@keyframes shake {
 0% { transform: translateX(0); }
 25% { transform: translateX(-5px); }
 75% { transform: translateX(5px); }
 100% { transform: translateX(0); }
}

.animate-shake {
 animation: shake 0.5s linear;
}